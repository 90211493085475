import * as React from "react"
import Layout from "../components/layout"

import { Helmet } from "react-helmet";

import {
    Container,
    Heading,
    Text,
    SlideFade,
    SimpleGrid,
    Grid,
    GridItem,
    Box,
    Link,
    Icon,
    HStack,
    Show
} from '@chakra-ui/react'

import { ExternalLinkIcon } from '@chakra-ui/icons'

import { FiTwitter } from 'react-icons/fi'
import { AiOutlineYoutube, AiOutlineMail } from 'react-icons/ai'

import { StaticImage } from "gatsby-plugin-image";

// markup
const AboutPage = () => {
    return (
        <Layout>
            <Helmet>
                <title>About | boo1213.work</title>
                <meta property="og:title" content="About | boo1213.work" />
            </Helmet>
            <Heading>About Me</Heading>
            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(8, 1fr)"}} marginTop={8} alignItems="center" gap={10}>
                <Show above="md">
                    <GridItem colSpan={2}>
                        <StaticImage src="../images/icon.jpg" style={{
                            borderRadius: "9999px"
                        }} alt="" />
                    </GridItem>
                </Show>
                <GridItem colSpan={6}>
                    <Heading bgGradient='linear-gradient(60deg, rgba(244,73,73,1) 0%, rgba(250,168,168,1) 35%, rgba(255,242,242,1) 100%);' bgClip="text">Hello, I'm boo!</Heading>
                    <Text marginTop="5">初めまして！私の名前はブーです。<br />MotionGraphicsや3DCGなどを駆使してMVやPVを制作しています！</Text>
                    <Text marginTop="2">ご依頼はTwitterのDMまたはメールまでお願い致します。</Text>
                    <HStack marginTop={5} spacing={5} fontSize="xl">
                        <HStack>
                            <Icon as={FiTwitter} />
                            <Link href="https://twitter.com/Boo_12_13_B" isExternal>Twitter</Link>
                        </HStack>
                        <HStack>
                            <Icon as={AiOutlineYoutube} />
                            <Link href="https://www.youtube.com/channel/UC6r8_P9P7qif5ABazQsaM5A" isExternal>YouTube</Link>
                        </HStack>
                        <HStack>
                            <Icon as={AiOutlineMail} />
                            <Link href="mailto:boo121322@gmail.com">Email</Link>
                        </HStack>
                    </HStack>
                </GridItem>
            </Grid>
        </Layout>
    )
}

export default AboutPage
